<template>
  <div>
    <div class="main-title">合作校区</div>
    <div class="content-list-page page-content">
      <div class="padding-t-sm padding-b-sm">
        <span class="fl-l margin-l-sm">
          <el-input
            v-model="searchData.name"
            clearable
            placeholder="校区名称"
            size="mini"
          ></el-input>
        </span>
        <span class="fl-l margin-l-sm">
          <el-select
            slot="prepend"
            v-model="searchType"
            placeholder="选择类型"
            size="mini"
          >
            <el-option :value="1" label="用户名称"></el-option>
            <el-option :value="2" label="用户ID"></el-option>
          </el-select>
        </span>
        <span class="fl-l margin-l-sm">
          <el-input
            v-if="searchType === 1"
            v-model="searchData.user_id"
            clearable
            placeholder="用户名称"
            size="mini"
          ></el-input>
        </span>

        <span class="fl-l margin-l-sm">
          <el-input
            v-if="searchType === 2"
            v-model="searchData.user_name"
            clearable
            placeholder="用户ID"
            size="mini"
          ></el-input>
        </span>

        <span class="fl-l margin-l-sm">
          <el-date-picker
            v-model="searchData.date_pick"
            end-placeholder="结束日期"
            range-separator="至"
            size="mini"
            start-placeholder="开始日期"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </span>

        <span class="margin-l-sm fl-l"> </span>

        <span class="margin-l-sm fl-l">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
            >搜索</el-button
          >
        </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <el-table
          ref="singleTable"
          v-loading="loading"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          highlight-current-row
          style="width: 100%; background: #fff"
        >
          <el-table-column align="center" label="ID" property="id" width="80" />
          <el-table-column align="center" label="校区名称" property="name" />
          <el-table-column
            align="center"
            label="用户名称"
            property="user_name"
          />
          <el-table-column align="center" label="用户id" property="user_id" />
          <el-table-column align="center" label="IP地址" property="ip" />
          <el-table-column align="center" label="来源地区" property="area" />
          <el-table-column
            align="center"
            label="加入时间"
            property="created_at"
          />
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 20]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            small
            @size-change="setPageSize"
            @current-change="setPage"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { config } from "../../../js/config";
import { mapActions } from "vuex";

export default {
  name: "list",
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      imageUrl: config.imageUrl,
      searchData: {},
      tableData: [],
      searchType: 1,
    };
  },
  methods: {
    ...mapActions("collaborate", ["recordList"]),

    search() {
      this.tableData = [];
      this.page = 1;
      this.getList();
    },
    async getList() {
      try {
        this.loading = true;
        const { data } = await this.recordList(this.searchData);
        this.tableData = data.list;
        this.total = data.total;
      } finally {
        this.loading = false;
      }
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },

    changeLevelAndGrade(e) {
      this.selectLevelAndGrade = e;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped>
.el-table .cell span.el-tag {
  margin-right: 5px;
  display: inline-block;
}
</style>